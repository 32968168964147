<template>
  <div
    class="v-auth-mini-container"
    @click="onClickContainer"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VAuthMiniContainer',

  methods: {
    onClickContainer() {
      this.$emit('on-click-container');
    },
  },
}
</script>

<style lang="scss" scoped>
.v-auth-mini-container {
  padding: 11px;
  background-color: #F4F4F6;
  border-radius: 8px;
  text-align: center;
}
</style>
